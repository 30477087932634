import React, { FC, useContext } from "react";
import { PageProps } from "gatsby";
import { Body } from "../components/Body";
import { TimestampCard } from "../components/Card";
import { Heading } from "../components/Heading";
import { Seo } from "../components/Seo";
import { StoryComponent } from "../components/StoryComponent";
import { Title } from "../components/Title";
import { Explanation } from "../data/Explanation";
import { DLC, Story } from "../types";
import { BackgroundFromKey } from "../components/Background";
import { LangContext } from "../context";
import { LangPrefix } from "../enums";

const DLCTemplate: FC<PageProps> = ({ pageContext }) => {
  const data = pageContext as unknown as DLC;
  const { langPrefix } = useContext(LangContext);
  return (
    <>
      <BackgroundFromKey k={data.background} />
      <Seo
        title={data.shortTitle}
        description={data.stories
          .map((story) => story.title + (story.time ? ` (${story.time})` : ""))
          .join(", ")}
      />
      <Body>
        <Title text={data.title} className={data.color} />
        <Explanation k={data.explanation} />
        <div className="w-full flex flex-col -mt-3">
          <div className="w-full flex flex-col py-5 -my-2" id="timestamp">
            <Heading
              text={
                langPrefix === LangPrefix.Ko
                  ? "시간표"
                  : langPrefix === LangPrefix.Cht
                  ? "時間表"
                  : "Timestamp"
              }
              hash="timestamp"
            />
            <TimestampCard data={data.stories} />
          </div>
          {data.stories.map((story: Story, idx) => (
            <StoryComponent key={idx} story={story} />
          ))}
        </div>
      </Body>
    </>
  );
};

export default DLCTemplate;
